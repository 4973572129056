<template>
    <Menubar class="navStyle" :model="menuItems" />
</template>

<script>
import Menubar from 'primevue/menubar';
import { NavigationType } from '../enums';

export default {
    name: 'Navbar',
    components: {
        Menubar
    },
    mounted() {
        const subMenuList = this.$store.getters.navigations.filter(x => x.type === NavigationType.SubMenu);

        this.menuItems = this.$store.getters.navigations
            .filter(x => x.type === NavigationType.MainMenu)
            .sort((x, y) => x.order - y.order)
            .map(nav => this.createMenuItem(nav, this.getSubMenuItems(subMenuList, nav.id)));
    },
    data() {
        return {
            menuItems: []
        };
    },
    methods: {
        createMenuItem(nav, subItems) {
            return {
                label: nav.displayName,
                items: subItems,
                to: nav.to
            };
        },
        getSubMenuItems(subMenuList, parentId) {
            const subMenuItems = subMenuList
                .filter(x => x.parentId === parentId)
                .sort((x, y) => x.order - y.order)
                .map(nav => ({
                    label: nav.displayName,
                    to: nav.to
                }));
            return subMenuItems.length > 0 ? subMenuItems : null;
        }
    }
};
</script>

<style scoped></style>
<style>
.navStyle.p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link {
    height: 35px;
}
.navStyle.p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link.router-link-active-exact {
    border-bottom: solid 0.3125rem #2e008b;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-weight: 500;
}
.navStyle.p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link:not(.router-link-active-exact) {
    border-radius: 1rem;
}
.navStyle.p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link {
    font-weight: 300;
    font-size: 1rem;
    color: black !important;
    background-color: rgb(248, 250, 255);
    border-radius: 0;
}
.navStyle.p-menubar {
    border: none;
    border-width: 0;
}
.navStyle.p-menubar .p-menuitem-link:not(.p-disabled):hover,
.navStyle.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover,
.navStyle.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:hover,
.navStyle.p-menubar .p-menubar-root-list > .p-menuitem .p-submenu-list > .p-menuitem > .p-menuitem-link:hover {
    background: #e9e6e6;
}
.navStyle.p-menubar .p-menuitem .p-submenu-icon,
.navStyle.p-menubar .p-menuitem .p-menuitem-icon {
    display: none;
}
.navStyle.p-menubar .p-menubar-root-list .p-menuitem {
    margin: 0 0.5rem;
    white-space: nowrap !important;
}
</style>
