<template>
  <div class="container container-width mt-5">
    <div class="row">
      <div class="col-md-3 p-0">
        <router-link :to="$store.getters.landingPage">
          <img src="../../assets/InventoryIQ.png" />
        </router-link>
      </div>
      <div class="col-md-6 "><Navbar /></div>
      <div class="col-md-3 p-0">
        <div class="row">
          <div class="col-md-10 p-0 text-end">
            <p class="mb-0 p-0 fs-6 font-weight-600">{{ this.userDetails && this.userDetails.name }}</p>
            <p class="mb-0 p-0 size12">{{ this.userDetails && this.getRole(this.userDetails.role) }}</p>
          </div>
          <div class="col-md-2" role="button" @click="openDialog">
            <img src="../../assets/HeaderUser.png" class="header-user-icon-size" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog position="topright" header=" " v-model:visible="display"
  :draggable="false" class="profile-popup" >
    <div class="box-row size14 font-weight-500" @click.prevent="changePassword()">CHANGE PASSWORD</div>
    <div class="box-row size14 font-weight-500 mt-3" @click.prevent="logoutUser()">LOGOUT</div>
  </Dialog>
</template>


<script>
import Dialog from 'primevue/dialog';
import { UserRole } from '../enums';
import Navbar from './Navbar.vue';
import 'primeicons/primeicons.css';
import { logout, web } from '../constants/constants';
import { AuthService, UserService } from '../../apis';
import { isNavigationAllowed } from '../../shared/utils/auth-utils';
import { NAVIGATIONCONST } from '../constants';

export default {
    name: 'Header',
    components: {
        Navbar,
        Dialog
    },
    data() {
        return {
            display: false
        };
    },
    methods: {
        getRole(roleId) {
            return Object.keys(UserRole).find(id=>UserRole[id]===roleId);
        },
        openDialog() {
            this.display = true;
        },
        closeDialog() {
            this.display = false;
        },
        logoutUser() {
            const unsubscribe = this.$store.getters.getUnsubscribe;
            unsubscribe.forEach(ele => ele());
            UserService.updateLoginLogoutDateTime({
                when: new Date(),
                what: logout,
                where: web,
                using: navigator.userAgent
            }, this.$store.getters.getSessionUser.uid).then(()=>{
                AuthService.logout().then(() => {
                    this.$router.replace({ path: '/login' });
                });
            });
        },
        changePassword() {
            this.$router.push( { path: '/changepassword' });
        }
    },
    computed: {
        userDetails() {
            return this.$store.getters.userDetails;
        }
    }
};
</script>

<style>
.header-user-icon-size{
height: 2.5rem;
width: 2.5rem;
}
.box-row{
  border:1px solid black;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 2.652rem;
  width: 13.4375rem;
  border-radius: 0.375rem;
}
.box-row:hover{
  background-color: #f3efef;
  cursor: pointer;
}
.profile-popup{
  width: 16rem !important;
  transform: translateY(5.7rem) !important;
}
</style>


